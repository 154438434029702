import {required, TextInput, useRecordContext, useTranslate} from "react-admin";
import * as React from "react";

const IssuerAccountProviderCreate = (props) => {
    const translate = useTranslate();
    const record = useRecordContext(props);
    const { provider, source } = props;
    console.log(record)

    if ((record && record.provider === 'eNett') || provider === 'eNett') {
        return (
            <div>
                <TextInput
                    source={source + ".eNett.requesterEcn"}
                    label={translate('kuggar.issuer_accounts.eNett_requester_ecn')}
                    fullWidth={true}
                    validate={[required()]} />
                <TextInput
                    source={source + ".eNett.issuedToEcn"}
                    label={translate('kuggar.issuer_accounts.eNett_issued_to_ecn')}
                    fullWidth={true}
                    validate={[required()]} />
                <TextInput
                    source={source + ".eNett.countryCode"}
                    label={translate('kuggar.issuer_accounts.eNett_country_code')}
                    fullWidth={true}
                    validate={[required()]} />
                <TextInput
                    source={source + ".eNett.username"}
                    label={translate('kuggar.issuer_accounts.eNett_username')}
                    fullWidth={true}
                />
            </div>
        );
    }
    else if (((record && record.provider === 'AirPlusFlight') || provider === 'AirPlusFlight') || 
            ((record && record.provider === 'AirPlusHotel') || provider === 'AirPlusHotel') || 
            ((record && record.provider === 'AirPlusProcurement') || provider === 'AirPlusProcurement')) {
        return (
            <TextInput
                source={source + '.airPlus.accountNumber'}
                label={translate('kuggar.issuer_accounts.airPlus_account_number')}
                fullWidth={true}
                validate={[required()]} />
        );
    }
    else if ((record && record.provider === 'Mastercard') || provider === 'Mastercard') {
        return (
            <div>
                <TextInput
                    source={source + ".mastercard.dataSourceId"}
                    label={translate('kuggar.issuer_accounts.mastercard_data_source_id')}
                    fullWidth={true}
                    validate={[required()]} />
                <TextInput
                    source={source + ".mastercard.companyId"}
                    label={translate('kuggar.issuer_accounts.mastercard_company_id')}
                    fullWidth={true}
                    validate={[required()]} />
                <TextInput
                    source={source + ".mastercard.rcnId"}
                    label={translate('kuggar.issuer_accounts.mastercard_rcn_id')}
                    fullWidth={true}
                    validate={[required()]} />
                <TextInput
                    source={source + ".mastercard.rcnAlias"}
                    label={translate('kuggar.issuer_accounts.mastercard_rcn_alias')}
                    fullWidth={true} />
                <TextInput
                    source={source + ".mastercard.supplierId"}
                    label={translate('kuggar.issuer_accounts.mastercard_supplier_id')}
                    fullWidth={true}
                    validate={[required()]} />
                <TextInput
                    source={source + ".mastercard.templateId"}
                    label={translate('kuggar.issuer_accounts.mastercard_template_id')}
                    fullWidth={true}
                    validate={[required()]} />
            </div>
        );
    }
    else if ((record && record.provider === 'Mint') || provider === 'Mint') {
        return (
            <TextInput
                source={source + ".mint.fundingAccountReference"}
                label={translate('kuggar.issuer_accounts.mint_funding_account_reference')}
                fullWidth={true}
                validate={[required()]} />
        );
    }
    else if ((record && record.provider === 'WEX') || provider === 'WEX') {
        return (
            <div>
                <TextInput
                    source={source + ".wex.bankNumber"}
                    label={translate('kuggar.issuer_accounts.wex_bank_number')}
                    fullWidth={true}
                    validate={[required()]} />
                <TextInput
                    source={source + ".wex.companyNumber"}
                    label={translate('kuggar.issuer_accounts.wex_company_number')}
                    fullWidth={true}
                    validate={[required()]} />
                <TextInput
                    source={source + ".wex.poolName"}
                    label={translate('kuggar.issuer_accounts.wex_pool_name')}
                    fullWidth={true} />
            </div>
        );
    }
    else if ((record && record.provider === 'Kuggar') || provider === 'Kuggar') {
        return (
            <div>
                <TextInput
                    source={source + ".kuggar.cardIssuerTemplateId"}
                    label={translate('kuggar.issuer_accounts.kuggar_card_issuer_template_id')}
                    fullWidth={true}
                    validate={[required()]} />
                <TextInput
                    source={source + ".kuggar.cardProcessorPolicyId"}
                    label={translate('kuggar.issuer_accounts.kuggar_card_processor_policy_id')}
                    fullWidth={true}
                    validate={[required()]} />
            </div>
        );
    }
    else {
        return null;
    }
};

export default IssuerAccountProviderCreate;