import {
    FunctionField,
    Labeled,
    PasswordInput,
    required,
    TextField,
    TextInput,
    useRecordContext,
    useTranslate
} from "react-admin";
import * as React from "react";

const IssuerCredentialsProviderData = (props) => {
    const translate = useTranslate();
    const {provider, source} = props;

    return (
        <>
            {provider === 'eNett' &&
                <>
                    <TextInput
                        source="issuerCredentialData.eNett.clientAccessKey"
                        label={translate('kuggar.issuer_credentials.eNett_client_access_key')}
                        fullWidth={true}
                        validate={[required()]}/>
                    <TextInput
                        source="issuerCredentialData.eNett.integratorCode"
                        label={translate('kuggar.issuer_credentials.eNett_integrator_code')}
                        fullWidth={true}
                        validate={[required()]}/>
                    <PasswordInput
                        source="issuerCredentialData.eNett.integratorAccessKey"
                        label={translate('kuggar.issuer_credentials.eNett_integrator_access_key')}
                        fullWidth={true}
                        validate={[required()]}/>
                </>
            }
            {(provider === 'AirPlusFlight' || provider === 'AirPlusHotel' || provider === 'AirPlusProcurement') &&
                <>
                    <TextInput
                        source="issuerCredentialData.airPlus.username"
                        label={translate('kuggar.issuer_credentials.airPlus_username')}
                        fullWidth={true}
                        validate={[required()]}/>
                    <PasswordInput
                        source="issuerCredentialData.airPlus.password"
                        label={translate('kuggar.issuer_credentials.airPlus_password')}
                        fullWidth={true}
                        validate={[required()]}/>
                </>
            }
            {provider === 'Mastercard' &&
                <>
                    <TextInput
                        source="issuerCredentialData.mastercard.consumerKey"
                        label={translate('kuggar.issuer_credentials.masterCard_consumer_key')}
                        fullWidth={true}
                        validate={[required()]}/>
                    <TextInput
                        source="issuerCredentialData.mastercard.pkcs12Base64"
                        label={translate('kuggar.issuer_credentials.masterCard_pkcs12base64')}
                        fullWidth={true}
                        validate={[required()]}/>
                    <PasswordInput
                        source="issuerCredentialData.mastercard.signingKeyPassword"
                        label={translate('kuggar.issuer_credentials.masterCard_signing_key_password')}
                        fullWidth={true}
                        validate={[required()]}/>
                </>

            }
            {provider === 'Mint' &&
                <>
                    <TextInput
                        source="issuerCredentialData.mint.apiKey"
                        label={translate('kuggar.issuer_credentials.mint_api_key')}
                        fullWidth={true}
                        validate={[required()]}/>
                    <PasswordInput
                        source="issuerCredentialData.mint.password"
                        label={translate('kuggar.issuer_credentials.mint_password')}
                        fullWidth={true}
                        validate={[required()]}/>
                </>

            }
            {provider === 'WEX' &&
                <>
                    <TextInput
                        source="issuerCredentialData.wex.orgGroupLoginId"
                        label={translate('kuggar.issuer_credentials.wex_org_group_login_id')}
                        fullWidth={true}
                        validate={[required()]}/>
                    <TextInput
                        source="issuerCredentialData.wex.username"
                        label={translate('kuggar.issuer_credentials.wex_username')}
                        fullWidth={true}
                        validate={[required()]}/>
                    <PasswordInput
                        source="issuerCredentialData.wex.password"
                        label={translate('kuggar.issuer_credentials.wex_password')}
                        fullWidth={true}
                        validate={[required()]}/>
                </>
            }
            {provider === 'Kuggar' &&
                <>
                    <TextInput
                        source="issuerCredentialData.kuggar.authUrl"
                        label={translate('kuggar.issuer_credentials.kuggar_auth_url')}
                        fullWidth={true}
                        validate={[required()]}/>
                    <TextInput
                        source="issuerCredentialData.kuggar.clientId"
                        label={translate('kuggar.issuer_credentials.kuggar_client_id')}
                        fullWidth={true}
                        validate={[required()]}/>
                    <PasswordInput
                        source="issuerCredentialData.kuggar.clientSecret"
                        label={translate('kuggar.issuer_credentials.kuggar_client_secret')}
                        fullWidth={true}
                        validate={[required()]}/>
                </>
            }
        </>
    )
}

export default IssuerCredentialsProviderData;