import {Datagrid, List, TextField, useRecordContext, useTranslate} from "react-admin";
import {TableFilter, TablePagination} from "../shared";
import * as React from "react";

export const AuthorisedDomainsList = () => {
    const translate = useTranslate();

    return (
        <List filters={<TableFilter />} pagination={false} sort={{ field: 'id', order: 'ASC' }}>
            <Datagrid bulkActionButtons={false}>
                <TextField source="key" label={translate("kuggar.authorised_domains.key_name")} />
                <TextField source="domain" label={translate("kuggar.authorised_domains.domain_name")} />
            </Datagrid>
        </List>
    );
};