import * as React from "react";
import {
    ArrayField,
    ArrayInput, BooleanField, Button, CheckboxGroupInput,
    Create, CreateButton,
    Datagrid,
    DeleteButton, downloadCSV,
    Edit,
    EditButton,
    email,
    EmailField,
    Error, ExportButton, FilterButton,
    FormDataConsumer,
    List,
    Loading,
    minLength,
    PasswordInput,
    ReferenceField,
    ReferenceInput,
    required, SelectColumnsButton,
    SelectInput,
    Show,
    SimpleForm,
    SimpleFormIterator,
    SimpleList,
    SimpleShowLayout,
    TextField,
    Title,
    TopToolbar, useDataProvider,
    useGetList,
    useGetOne, useListContext,
    useNotify,
    useRecordContext,
    useRedirect, useRefresh,
    useTranslate
} from 'react-admin';
import {
    DecodeJWT,
    GetMinRoleLevel,
    HasCreatePermission,
    HasDeletePermission,
    HasUpdatePermission, kuggarExporter, KuggarTextInput,
    TableFilter,
    TablePagination,
    TextFieldWithCopyButton,
    UpdateToolbar
} from '../shared';
import CardContent from "@mui/material/CardContent";
import Card from "@mui/material/Card";
import {Link as RouterLink} from "react-router-dom";
import { Link } from '@mui/material';
import ImportExportIcon from '@mui/icons-material/ImportExport';
import {Checkbox, FormControlLabel, FormGroup} from '@mui/material'
import {useEffect, useState} from "react";
import jsonExport from 'jsonexport/dist';
import {styled} from "@mui/material/styles";
import RefreshIcon from "@mui/icons-material/Downloading";
import LockIcon from '@mui/icons-material/Lock';
import LockOpenIcon from '@mui/icons-material/LockOpen';
import dataProvider from "../dataProvider";


const OrganisationSelectInput = () => {
    const translate = useTranslate();

    const {data, isLoading, error} = useGetList(
        'organisation',
        {
            filter: {},
            pagination: {
                page: 1,
                perPage: 1000
            },
            sort: {
                field: 'name',
                order: 'ASC'
            }
        }
    );
    if (isLoading) return <Loading/>;
    if (error) return <Error/>;
    if (!data) return null;

    const OptionRenderer = () => {
        const record = useRecordContext();
        return <span>{record.name} ({record.referenceCode})</span>
    };

    return (
        <SelectInput source="organisationId" label={translate('kuggar.organisation.organisation')}
                     validate={[required()]} fullWidth={true} choices={data} optionText={<OptionRenderer/>}
                     optionValue="id"/>
    );
};

const GetRoles = () => {
    const {data, isLoading, error} = useGetList(
        'role',
        {
            filter: {},
            pagination: {
                page: 1,
                perPage: 1000
            },
            sort: {
                field: 'level',
                order: 'ASC'
            }
        }
    );
    if (isLoading || error || !data) return [];

    const highestRoleLevel = GetMinRoleLevel();

    return data.map(role => ({...role, disabled: role.level < highestRoleLevel}));
}

const GetRoleScopes = (roleId) => {
    const {data: role, isLoading, error} = useGetOne('role', {id: roleId});
    if (isLoading || error) return [];
    const highestRoleLevel = GetMinRoleLevel();
    return role.scopes.map(scope => ({id: scope, name: scope, disabled: role.level < highestRoleLevel}));
}

const RoleSelectArrayInput = () => {
    const translate = useTranslate();

    const optionRenderer = choice => <div>{choice.name}<br/><span
        style={{fontSize: '0.875rem', color: 'rgba(0,0,0,0.6)'}}>Level {choice.level}. {choice.description}</span>
    </div>;

    return (
        <ArrayInput source="roles" fullWidth={true} >
            <SimpleFormIterator>
                <SelectInput source="id" label={translate('kuggar.shared.name')} choices={GetRoles()} optionText={optionRenderer} fullWidth={true} data-testid='RolesList' />
                <FormDataConsumer>
                    {({ scopedFormData, getSource }) => scopedFormData.id &&
                        <SelectInput source={getSource('scope')} label={translate('kuggar.role.scope')} choices={GetRoleScopes(scopedFormData.id)} optionText="name" fullWidth={true} data-testid='RolesList' />
                    }
                </FormDataConsumer>
            </SimpleFormIterator>
        </ArrayInput>
    );
};

const AuthenticationSelectInputCreate = () => {
    const translate = useTranslate();
    
    return (
        <SelectInput source="authenticationMethod" label={translate('kuggar.user.authentication_method')} 
                     fullWidth={true} validate={[required()]} choices={authenticationMethodChoices}/>
    );
}

const AuthenticationSelectInputEdit = () => {
    const translate = useTranslate();
    const record = useRecordContext();
    const {data: credential, isLoading, error} = useGetOne('credential', {id: record.credentialId});
    if(isLoading) return;
    
    return (
        <SelectInput source="authenticationMethod" label={translate('kuggar.user.authentication_method')} 
                     fullWidth={true} validate={[required()]} defaultValue={credential.authenticationMethod} choices={authenticationMethodChoices}/>
    );
}

const authenticationMethodChoices = [
    {id: 'Kuggar', name: 'API-only Users'},
    {id: 'Microsoft', name: 'Microsoft'},
    {id: 'Google', name: 'Google'},
    {id: 'Okta', name: 'Okta'}
]

const ImportButton = () => (
    <Link component={RouterLink} to="/user/import">
        <Button startIcon={<ImportExportIcon />} label='Import' />
    </Link>
);

const ListActions = () => {
    const { total, isLoading } = useListContext();
    const hasCreate = HasCreatePermission('Platform', '/user')
    
    const HasImportPermission = () => {
        const token = localStorage.getItem('auth')
        const jsonToken = DecodeJWT(token)
        
        return jsonToken.identityProvider !== 'Kuggar'
    }
    
    return (
        <TopToolbar>
            { hasCreate && <CreateButton/>}
            { HasImportPermission() && <ImportButton />}
            <ExportButton disabled={isLoading || total === 0} />
        </TopToolbar>
    )
}

export const UserList = () => {
    const translate = useTranslate();
    
    return (
        <List exporter={kuggarExporter} filters={<TableFilter/>} pagination={<TablePagination/>} perPage={20}
              sort={{field: 'username', order: 'ASC'}} actions={<ListActions />}>
            <Datagrid rowClick="show" bulkActionButtons={false}>
                <TextField source="id" label={translate('kuggar.shared.id')}/>
                <EmailField source="username" label={translate('kuggar.user.username')}/>
                <TextField source="organisationName" label={translate('kuggar.organisation.organisation_name')}/>
                <TextField source="organisationRef" label={translate('kuggar.organisation.organisation_ref')}/>
                <ArrayField source="roles" label={translate('kuggar.user.roles')}>
                    <SimpleList
                        primaryText={record => record.name}
                        secondaryText={record => record.scope}
                        linkType={false}
                        rowStyle={record => ({marginTop: 0, marginBottom: 0})}
                        sx={{paddingTop: 0, paddingBottom: 0}}
                    />
                </ArrayField>
                <ReferenceField source="virtualCardsAccessProfileId"
                                label={translate('kuggar.user.virtualCardsAccessProfile')} reference="vc_access_profile"
                                link="show">
                    <TextField source="description"/>
                </ReferenceField>
            </Datagrid>
        </List>
    );
};

export const UserShow = () => {
    const translate = useTranslate();
    const notify = useNotify();
    const redirect = useRedirect();
    const refresh = useRefresh();

    const Title = () => {
        const record = useRecordContext();
        return <span>User - ID {record ? record.id : ''}</span>;
    };

    const onSuccess = () => {
		notify(`${translate('kuggar.user.on_delete_success_notify')}`);
		redirect(`/user`);
    };
    
    const onError = (data) => {
        notify(`${translate('kuggar.user.on_delete_error_notify')}: ${data.body.error}`, { type: 'error' });
    };

    const Actions = () => {
        const data = useRecordContext();
        const { data: credential, isLoading, error } = useGetOne('credential', { id: data?.credentialId });
        if(isLoading || error) return null;

        return (
			<TopToolbar>
                {HasUpdatePermission('Platform', '/user/') ?
                    <Button
                        label={credential?.isLocked ? 'Unlock' : 'Lock'}
                        startIcon={credential?.isLocked ? <LockOpenIcon /> : <LockIcon /> }
                        onClick={() => handleLockClick(credential)} />
                    : null}
				{HasUpdatePermission('Platform', '/user/') ?
					<EditButton
						label={translate('kuggar.user.update_user')}
						record={data} />
				: null}
				{HasDeletePermission('Platform', '/user/') ?
					<DeleteButton
						label={translate('kuggar.user.delete_user')}
						record={data}
                        mutationMode="optimistic"
                        mutationOptions={{ onSuccess, onError }}
						confirmTitle="Delete User"
						confirmContent={`Are you sure you want to delete User with Kuggar ID ${data ? data.id : ''}? This action cannot be undone.`} />
				: null}
			</TopToolbar>
		);
	};

    const handleLockClick = (credential) => {
        if (credential) {
            dataProvider.lockCredential({ id: credential.id, isLocked: !credential.isLocked }).then(() => {
                notify('User locked successfully', 'info');
                refresh();
            })
            .catch(error => {
                notify(`${error.message}`, 'warning'); 
            });
        }
    };

    return (
        <Show title={<Title/>} actions={<Actions/>}>
            <SimpleShowLayout>
                <TextFieldWithCopyButton source='id' label={translate('kuggar.shared.id')}/>
                <EmailField source="username" label={translate('kuggar.user.username')}/>
                <TextField source="organisationName" label={translate('kuggar.organisation.organisation_name')}/>
                <TextField source="organisationRef" label={translate('kuggar.organisation.organisation_ref')}/>
                <ArrayField source="roles" label={translate('kuggar.user.roles')}>
                    <Datagrid bulkActionButtons={false}>
                        <TextField source="name"/>
                        <TextField source="scope"/>
                    </Datagrid>
                </ArrayField>
                <ReferenceField source="credentialId"
                                label='Is Locked' reference="credential"
                                link={false}>
                    <BooleanField source="isLocked"/>
                </ReferenceField>
                <TextField source="externalId" label={translate('kuggar.shared.external_id')}/>
                <ReferenceField source="credentialId"
                                label={translate('kuggar.user.authentication_method')} reference="credential"
                                link={false}>
                    <TextField source="authenticationMethod"/>
                </ReferenceField>
                <ReferenceField source="virtualCardsAccessProfileId"
                                label={translate('kuggar.user.virtualCardsAccessProfile')} reference="vc_access_profile"
                                link="show">
                    <TextField source="description"/>
                </ReferenceField>
            </SimpleShowLayout>
        </Show>
    );
};

export const UserEdit = () => {
    const translate = useTranslate();

    return (
        <Edit mutationMode="pessimistic">
            <SimpleForm toolbar={<UpdateToolbar/>}>
                <KuggarTextInput source="id" label={translate('kuggar.shared.id')} fullWidth={true} disabled/>
                <AuthenticationSelectInputEdit  />
                <KuggarTextInput source="username" label={translate('kuggar.user.username')} fullWidth={true}
                           validate={[required(), email('kuggar.shared.email_address_validation')]}/>
                <FormDataConsumer>
                    {({formData}) => formData.authenticationMethod === 'Kuggar' && (
                        <>
                            <PasswordInput source="currentPassword" label={translate('kuggar.user.current_password')}
                                           fullWidth={true} validate={[required(), minLength(8)]}/>
                            <PasswordInput source="newPassword" label={translate('kuggar.user.new_password')}
                                           fullWidth={true} validate={[required(), minLength(8)]}/>
                        </>
                    )}
                </FormDataConsumer>
                <OrganisationSelectInput/>
                <RoleSelectArrayInput/>
                <KuggarTextInput source="externalId" label={translate('kuggar.shared.external_id')} fullWidth={true}/>
                <ReferenceInput source="virtualCardsAccessProfileId" reference="vc_access_profile"
                                filter={{is_published: true}}>
                    <SelectInput optionText="description" label={translate('kuggar.user.virtualCardsAccessProfile')}
                                 fullWidth={true}/>
                </ReferenceInput>
            </SimpleForm>
        </Edit>
    );
};

export const UserCreate = () => {
    const notify = useNotify();
    const redirect = useRedirect();
    const translate = useTranslate();

    const onSuccess = () => {
        notify(`${translate('kuggar.user.on_success_notify')}`);
        redirect(`/user`);
    };

    const onError = (data) => {
        notify(data.body.error, { type: 'error' });
    };

    return (
        <Create mutationOptions={{ onSuccess, onError }}>
            <SimpleForm>
                <AuthenticationSelectInputCreate  />
                <KuggarTextInput source="username" label={translate('kuggar.user.username')} fullWidth={true}
                           validate={[required(), email('kuggar.shared.email_address_validation')]}/>
                <FormDataConsumer>
                    {({formData}) => formData.authenticationMethod === 'Kuggar' &&
                        <PasswordInput source="password" label={translate('kuggar.user.password')} fullWidth={true}
                                       validate={[required(), minLength(8)]}/>
                    }
                </FormDataConsumer>
                <OrganisationSelectInput/>
                <RoleSelectArrayInput/>
                <KuggarTextInput source="externalId" label={translate('kuggar.shared.external_id')} fullWidth={true}/>
                <ReferenceInput source="virtualCardsAccessProfileId" reference="vc_access_profile"
                                filter={{is_published: true}}>
                    <SelectInput optionText="description" label={translate('kuggar.user.virtualCardsAccessProfile')}
                                 fullWidth={true}/>
                </ReferenceInput>
            </SimpleForm>
        </Create>
    );
};

export const ImportUsers = () => {
    const dataProvider = useDataProvider();
    const translate = useTranslate();
    const redirect = useRedirect();
    const notify = useNotify();
    const [externalUsers, setExternalUsers] = useState([])
    const [isLoading, setIsLoading] = useState(true)
    const [checkedItems, setCheckedItems] = useState([]);


    useEffect(() => {
        dataProvider.getExternalUsers()
            .then(({data}) => {
                setExternalUsers(data.filter(user => user.email !== null))
                setIsLoading(false)
            })
    }, [])

    const handleCheckboxChange = (event) => {
        setCheckedItems({
            ...checkedItems,
            [event.target.name]: event.target.checked,
        });
    };

    const handleSubmit = () => {
        const selectedUsers = externalUsers.filter(user => checkedItems[user.email]);
        const token = DecodeJWT(localStorage.getItem('auth'))
        const authenticationMethod = getAuthenticationMethod(token.identityProvider)

        const createUserCommands = selectedUsers.map(user => {
            return {
                username: user.email,
                externalId: user.externalId,
                organisationId: token.organisationId,
                authenticationMethod
            };
        });
        
        dataProvider.bulkCreateUsers(createUserCommands)
            .then(() => {
                notify(`${translate('kuggar.user.import.on_success_notify')}`);
                redirect(`/user`);
            })
    };

    const getAuthenticationMethod = (provider) => {
        switch (provider) {
            case 'Kuggar':
                return 'Kuggar'
            case 'AzureAD':
                return 'Microsoft'
            case 'Google':
                return 'Google'
            case 'Okta':
                return 'Okta'
        }
    }

    if(isLoading) return <Loading />
    
    return (
        <Card sx={{marginTop: '60px'}}>
            <CardContent>
                <FormGroup>
                    {externalUsers.map(user => (
                        <FormControlLabel
                            key={user.email}
                            control={
                                <Checkbox
                                    checked={checkedItems[user.email] || false}
                                    onChange={handleCheckboxChange}
                                    disabled={user.exists}
                                    name={user.email}
                                />
                            }
                            label={user.email}
                        />
                    ))}
                </FormGroup>
                <Button 
                    startIcon={<ImportExportIcon />} 
                    variant="contained" 
                    color="primary" 
                    onClick={handleSubmit} 
                    disabled={!Object.values(checkedItems).some(value => value)} 
                    label={translate('kuggar.user.import.import')} 
                    sx={{padding: '10px', marginTop: '20px'}}/>
            </CardContent>
        </Card>
    )
}