import {
	useTranslate,
	DateInput,
	useRecordContext,
	NumberInput,
	TextInput,
	BooleanInput,
	ArrayInput,
	SimpleFormIterator,
	SelectInput,
	required
} from 'react-admin';

const CardCreateRequestEnettForm = props => {
	const translate = useTranslate();
	const { source } = props;
	const inputStyle = { width: '512px' };

	return (
		<span>
			<div>
				<DateInput
					source={source + '.eNett.activationDate'}
					label={translate('kuggar.eNett_create_request.activation_date')}
					style={inputStyle} />
			</div>
			<div>
				<DateInput
					source={source + '.eNett.expiryDate'}
					label={translate('kuggar.eNett_create_request.expiry_date')}
					style={inputStyle} />
			</div>
			<div>
				<NumberInput
					source={source + '.eNett.minimumAuthorisationAmount'}
					label={translate('kuggar.eNett_create_request.minimum_authorisation_amount')}
					min="0"
					style={inputStyle} />
			</div>
			<div>
				<NumberInput
					source={source + '.eNett.maximumAuthorisationAmount'}
					label={translate('kuggar.eNett_create_request.maximum_authorisation_amount')}
					min="1"
					style={inputStyle} />
			</div>
			<div>
				<TextInput
					source={source + '.eNett.currencyCode'}
					label={translate('kuggar.eNett_create_request.currency_code')} />
			</div>
			<div>
				<TextInput
					source={source + '.eNett.fundingCurrencyCode'}
					label={translate('kuggar.eNett_create_request.funding_currency_code')} />
			</div>
			<div>
				<TextInput
					source={source + '.eNett.integratorReference'}
					label={translate('kuggar.eNett_create_request.integrator_reference')} />
			</div>
			<div>
				<BooleanInput
					source={source + '.eNett.isMultiUse'}
					label={translate('kuggar.eNett_create_request.is_multi_use')} />
			</div>
			<div>
				<TextInput
					source={source + '.eNett.merchantCategoryName'}
					label={translate('kuggar.eNett_create_request.merchant_category_name')} />
			</div>
			<div>
				<TextInput
					source={source + '.eNett.notes'}
					label={translate('kuggar.eNett_create_request.notes')} />
			</div>
			<div>
				<ArrayInput label={translate('kuggar.eNett_create_request.user_references')} source={source + '.eNett.userReferences'}>
					<SimpleFormIterator>
						<TextInput source="label" label={translate('kuggar.shared.label')} />
						<TextInput source="value" label={translate('kuggar.shared.value')} />
					</SimpleFormIterator>
				</ArrayInput>
			</div>
		</span>
	);
};

const CardCreateRequestAirPlusFlightForm = props => {
	const translate = useTranslate();
	const { source } = props;
	const inputStyle = { width: '512px' };

	return (
		<span>
			<div>
				<TextInput
					source={source + '.airPlusFlight.passengerName'}
					label={translate('kuggar.air_plus_flight_create_request.passenger_name')} />
			</div>
			<div>
				<DateInput
					source={source + '.airPlusFlight.flightDate'}
					label={translate('kuggar.air_plus_flight_create_request.flight_date')}
					style={inputStyle} />
			</div>
			<div>
				<NumberInput
					source={source + '.airPlusFlight.limit'}
					label={translate('kuggar.air_plus_create_request.limit')}
					min="1"
					validate={[required()]}
					style={inputStyle} />
			</div>
			<div>
				<TextInput
					source={source + '.airPlusFlight.limitCurrencyNumericCode'}
					style={inputStyle}
					label={translate('kuggar.air_plus_create_request.limit_currency_numeric_code')} />
			</div>
						<div>
				<DateInput
					source={source + '.airPlusFlight.validFromDate'}
					label={translate('kuggar.air_plus_create_request.valid_from_date')}
					style={inputStyle} />
			</div>
			<div>
				<DateInput
					source={source + '.airPlusFlight.validToDate'}
					label={translate('kuggar.air_plus_create_request.valid_to_date')}
					style={inputStyle} />
			</div>
			<div>
				<TextInput
					source={source + '.airPlusFlight.departureAirportCode'}
					label={translate('kuggar.air_plus_flight_create_request.departure_airport_code')} />
			</div>
			<div>
				<TextInput
					source={source + '.airPlusFlight.destinationAirportCode'}
					label={translate('kuggar.air_plus_flight_create_request.destination_airport_code')} />
			</div>
			<div>
				<TextInput
					source={source + '.airPlusFlight.airlineCode'}
					label={translate('kuggar.air_plus_flight_create_request.airline_code')} />
			</div>
			<div>
				<SelectInput source={source + '.airPlusFlight.flightClass'}
					label={translate('kuggar.air_plus_flight_create_request.flight_class')}
					style={inputStyle}
					choices={[
						{ id: 'Undefined', name: 'Undefined' },
						{ id: 'First', name: 'First' },
						{ id: 'Business', name: 'Business' },
						{ id: 'Economy', name: 'Economy' },
						{ id: 'Other', name: 'Other' }
					]}
				/>
			</div>
			<div>
				<ArrayInput  label={translate('kuggar.air_plus_create_request.transaction_currency_numeric_codes')} source={source + '.airPlusFlight.transactionCurrencyNumericCodes'}>
					<SimpleFormIterator>
						<TextInput label=""/>
					</SimpleFormIterator>
				</ArrayInput>
			</div>
			<div>
				<TextInput
					source={source + '.airPlusFlight.merchantName'}
					label={translate('kuggar.air_plus_create_request.merchant_name')} />
			</div>
			<div>
				<TextInput
					source={source + '.airPlusFlight.merchantCountryNumericCode'}
					style={inputStyle}
					label={translate('kuggar.air_plus_create_request.merchant_country_numeric_code')} />
			</div>
			<div>
				<TextInput
					source={source + '.airPlusFlight.generatingUserName'}
					label={translate('kuggar.air_plus_create_request.generating_user_name')} />
			</div>
			<div>
				<TextInput
					source={source + '.airPlusFlight.generatingUserMail'}
					label={translate('kuggar.air_plus_create_request.generating_user_mail')} />
			</div>
			<div>
				<TextInput
					source={source + '.airPlusFlight.generatingUserID'}
					label={translate('kuggar.air_plus_create_request.generating_user_id')} />
			</div>
			<div>
				<TextInput
					source={source + '.airPlusFlight.generatingUserPhone'}
					label={translate('kuggar.air_plus_create_request.generating_user_phone')} />
			</div>
			<div>
				<TextInput
					source={source + '.airPlusFlight.cardUserName'}
					label={translate('kuggar.air_plus_create_request.card_user_name')} />
			</div>
			<div>
				<TextInput
					source={source + '.airPlusFlight.cardUserMail'}
					label={translate('kuggar.air_plus_create_request.card_user_mail')} />
			</div>
			<div>
				<TextInput
					source={source + '.airPlusFlight.cardUserID'}
					label={translate('kuggar.air_plus_create_request.card_user_id')} />
			</div>
			<div>
				<TextInput
					source={source + '.airPlusFlight.cardUserPhone'}
					label={translate('kuggar.air_plus_create_request.card_user_phone')} />
			</div>
			<div>
				<TextInput
					source={source + '.airPlusFlight.dbiAccountingUnit'}
					label={translate('kuggar.air_plus_create_request.dbi_accounting_unit')} />
			</div>
			<div>
				<TextInput
					source={source + '.airPlusFlight.dbiAccountingCode'}
					label={translate('kuggar.air_plus_create_request.dbi_accounting_code')} />
			</div>
			<div>
				<TextInput
					source={source + '.airPlusFlight.dbiOrderNumber'}
					label={translate('kuggar.air_plus_create_request.dbi_order_number')} />
			</div>
			<div>
				<DateInput
					source={source + '.airPlusFlight.dbiDepartureDate'}
					label={translate('kuggar.air_plus_create_request.dbi_departure_date')}
					style={inputStyle} />
			</div>
			<div>
				<TextInput
					source={source + '.airPlusFlight.dbiDepartmentCode'}
					label={translate('kuggar.air_plus_create_request.dbi_department_code')} />
			</div>
			<div>
				<TextInput
					source={source + '.airPlusFlight.dbiInternalAccount'}
					label={translate('kuggar.air_plus_create_request.dbi_internal_account')} />
			</div>
			<div>
				<TextInput
					source={source + '.airPlusFlight.dbiCostCentre'}
					label={translate('kuggar.air_plus_create_request.dbi_cost_centre')} />
			</div>
			<div>
				<TextInput
					source={source + '.airPlusFlight.dbiEmployeeNumber'}
					label={translate('kuggar.air_plus_create_request.dbi_employee_number')} />
			</div>
			<div>
				<TextInput
					source={source + '.airPlusFlight.dbiProjectNumber'}
					label={translate('kuggar.air_plus_create_request.dbi_project_number')} />
			</div>
			<div>
				<TextInput
					source={source + '.airPlusFlight.dbiDestination'}
					label={translate('kuggar.air_plus_create_request.dbi_destination')} />
			</div>
		</span>
	);
};

const CardCreateRequestAirPlusHotelForm = props => {
	const translate = useTranslate();
	const { source } = props;
	const inputStyle = { width: '512px' };

	return (
		<span>
			<div>
				<TextInput
					source={source + '.airPlusHotel.guestName'}
					label={translate('kuggar.air_plus_hotel_create_request.guest_name')} />
			</div>
			<div>
				<DateInput
					source={source + '.airPlusHotel.checkInDate'}
					label={translate('kuggar.air_plus_hotel_create_request.check_in_date')}
					style={inputStyle} />
			</div>
			<div>
				<DateInput
					source={source + '.airPlusHotel.checkOutDate'}
					label={translate('kuggar.air_plus_hotel_create_request.check_out_date')}
					style={inputStyle} />
			</div>
			<div>
				<NumberInput
					source={source + '.airPlusHotel.roomNights'}
					label={translate('kuggar.air_plus_hotel_create_request.room_nights')}
					style={inputStyle} />
			</div>
			<div>
				<DateInput
					source={source + '.airPlusHotel.validFromDate'}
					label={translate('kuggar.air_plus_create_request.valid_from_date')}
					style={inputStyle} />
			</div>
			<div>
				<DateInput
					source={source + '.airPlusHotel.validToDate'}
					label={translate('kuggar.air_plus_create_request.valid_to_date')}
					style={inputStyle} />
			</div>
			<div>
				<NumberInput
					source={source + '.airPlusHotel.limit'}
					label={translate('kuggar.air_plus_create_request.limit')}
					min="1"
					style={inputStyle} />
			</div>
			<div>
				<TextInput
					source={source + '.airPlusHotel.limitCurrencyNumericCode'}
					style={inputStyle}
					label={translate('kuggar.air_plus_create_request.limit_currency_numeric_code')} />
			</div>
			<div>
				<ArrayInput label={translate('kuggar.air_plus_create_request.transaction_currency_numeric_codes')} source={source + '.airPlusHotel.transactionCurrencyNumericCodes'}>
					<SimpleFormIterator>
						<TextInput label="" />
					</SimpleFormIterator>
				</ArrayInput>
			</div>
			<div>
				<TextInput
					source={source + '.airPlusHotel.merchantName'}
					label={translate('kuggar.air_plus_create_request.merchant_name')} />
			</div>
			<div>
				<TextInput
					source={source + '.airPlusHotel.merchantCountryNumericCode'}
					style={inputStyle}
					label={translate('kuggar.air_plus_create_request.merchant_country_numeric_code')} />
			</div>
			<div>
				<TextInput
					source={source + '.airPlusHotel.generatingUserName'}
					label={translate('kuggar.air_plus_create_request.generating_user_name')} />
			</div>
			<div>
				<TextInput
					source={source + '.airPlusHotel.generatingUserMail'}
					label={translate('kuggar.air_plus_create_request.generating_user_mail')} />
			</div>
			<div>
				<TextInput
					source={source + '.airPlusHotel.generatingUserID'}
					label={translate('kuggar.air_plus_create_request.generating_user_id')} />
			</div>
			<div>
				<TextInput
					source={source + '.airPlusHotel.generatingUserPhone'}
					label={translate('kuggar.air_plus_create_request.generating_user_phone')} />
			</div>
			<div>
				<TextInput
					source={source + '.airPlusHotel.cardUserName'}
					label={translate('kuggar.air_plus_create_request.card_user_name')} />
			</div>
			<div>
				<TextInput
					source={source + '.airPlusHotel.cardUserMail'}
					label={translate('kuggar.air_plus_create_request.card_user_mail')} />
			</div>
			<div>
				<TextInput
					source={source + '.airPlusHotel.cardUserID'}
					label={translate('kuggar.air_plus_create_request.card_user_id')} />
			</div>
			<div>
				<TextInput
					source={source + '.airPlusHotel.cardUserPhone'}
					label={translate('kuggar.air_plus_create_request.card_user_phone')} />
			</div>
			<div>
				<TextInput
					source={source + '.airPlusHotel.dbiAccountingUnit'}
					label={translate('kuggar.air_plus_create_request.dbi_accounting_unit')} />
			</div>
			<div>
				<TextInput
					source={source + '.airPlusHotel.dbiAccountingCode'}
					label={translate('kuggar.air_plus_create_request.dbi_accounting_code')} />
			</div>
			<div>
				<TextInput
					source={source + '.airPlusHotel.dbiOrderNumber'}
					label={translate('kuggar.air_plus_create_request.dbi_order_number')} />
			</div>
			<div>
				<DateInput
					source={source + '.airPlusHotel.dbiDepartureDate'}
					label={translate('kuggar.air_plus_create_request.dbi_departure_date')}
					style={inputStyle} />
			</div>
			<div>
				<TextInput
					source={source + '.airPlusHotel.dbiDepartmentCode'}
					label={translate('kuggar.air_plus_create_request.dbi_department_code')} />
			</div>
			<div>
				<TextInput
					source={source + '.airPlusHotel.dbiInternalAccount'}
					label={translate('kuggar.air_plus_create_request.dbi_internal_account')} />
			</div>
			<div>
				<TextInput
					source={source + '.airPlusHotel.dbiCostCentre'}
					label={translate('kuggar.air_plus_create_request.dbi_cost_centre')} />
			</div>
			<div>
				<TextInput
					source={source + '.airPlusHotel.dbiEmployeeNumber'}
					label={translate('kuggar.air_plus_create_request.dbi_employee_number')} />
			</div>
			<div>
				<TextInput
					source={source + '.airPlusHotel.dbiProjectNumber'}
					label={translate('kuggar.air_plus_create_request.dbi_project_number')} />
			</div>
			<div>
				<TextInput
					source={source + '.airPlusHotel.dbiDestination'}
					label={translate('kuggar.air_plus_create_request.dbi_destination')} />
			</div>
		</span>
	);
};

const CardCreateRequestAirPlusProcurementForm = props => {
	const translate = useTranslate();
	const { source } = props;
	const inputStyle = { width: '512px' };

	return (
		<span>
			<div>
				<SelectInput source={source + '.airPlusProcurement.procurementType'}
					label={translate('kuggar.air_plus_procurement_create_request.procurement_type')}
					style={inputStyle}
					choices={[
						{ id: 'Parking', name: 'Parking' },
						{ id: 'Retail', name: 'Retail' },
						{ id: 'Telecomms', name: 'Telecomms' },
						{ id: 'Other Procurement', name: 'OtherProcurement' }
					]}
				/>
			</div>
			<div>
				<TextInput
					source={source + '.airPlusProcurement.name'}
					label={translate('kuggar.air_plus_procurement_create_request.name')} />
			</div>
			<div>
				<TextInput
					source={source + '.airPlusProcurement.freeText'}
					label={translate('kuggar.air_plus_procurement_create_request.free_text')} />
			</div>
			<div>
				<DateInput
					source={source + '.airPlusProcurement.validFromDate'}
					label={translate('kuggar.air_plus_create_request.valid_from_date')}
					style={inputStyle} />
			</div>
			<div>
				<DateInput
					source={source + '.airPlusProcurement.validToDate'}
					label={translate('kuggar.air_plus_create_request.valid_to_date')}
					style={inputStyle} />
			</div>
			<div>
				<NumberInput
					source={source + '.airPlusProcurement.limit'}
					label={translate('kuggar.air_plus_create_request.limit')}
					min="1"
					style={inputStyle} />
			</div>
			<div>
				<TextInput
					source={source + '.airPlusProcurement.limitCurrencyNumericCode'}
					style={inputStyle}
					label={translate('kuggar.air_plus_create_request.limit_currency_numeric_code')} />
			</div>
			<div>
				<ArrayInput  label={translate('kuggar.air_plus_create_request.transaction_currency_numeric_codes')} source={source + '.airPlusProcurement.transactionCurrencyNumericCodes'}>
					<SimpleFormIterator>
						<TextInput label=""/>
					</SimpleFormIterator>
				</ArrayInput>
			</div>
			<div>
				<TextInput
					source={source + '.airPlusProcurement.merchantName'}
					label={translate('kuggar.air_plus_create_request.merchant_name')} />
			</div>
			<div>
				<TextInput
					source={source + '.airPlusProcurement.merchantCountryNumericCode'}
					style={inputStyle}
					label={translate('kuggar.air_plus_create_request.merchant_country_numeric_code')} />
			</div>
			<div>
				<TextInput
					source={source + '.airPlusProcurement.generatingUserName'}
					label={translate('kuggar.air_plus_create_request.generating_user_name')} />
			</div>
			<div>
				<TextInput
					source={source + '.airPlusProcurement.generatingUserMail'}
					label={translate('kuggar.air_plus_create_request.generating_user_mail')} />
			</div>
			<div>
				<TextInput
					source={source + '.airPlusProcurement.generatingUserID'}
					label={translate('kuggar.air_plus_create_request.generating_user_id')} />
			</div>
			<div>
				<TextInput
					source={source + '.airPlusProcurement.generatingUserPhone'}
					label={translate('kuggar.air_plus_create_request.generating_user_phone')} />
			</div>
			<div>
				<TextInput
					source={source + '.airPlusProcurement.cardUserName'}
					label={translate('kuggar.air_plus_create_request.card_user_name')} />
			</div>
			<div>
				<TextInput
					source={source + '.airPlusProcurement.cardUserMail'}
					label={translate('kuggar.air_plus_create_request.card_user_mail')} />
			</div>
			<div>
				<TextInput
					source={source + '.airPlusProcurement.cardUserID'}
					label={translate('kuggar.air_plus_create_request.card_user_id')} />
			</div>
			<div>
				<TextInput
					source={source + '.airPlusProcurement.cardUserPhone'}
					label={translate('kuggar.air_plus_create_request.card_user_phone')} />
			</div>
			<div>
				<TextInput
					source={source + '.airPlusProcurement.dbiAccountingUnit'}
					label={translate('kuggar.air_plus_create_request.dbi_accounting_unit')} />
			</div>
			<div>
				<TextInput
					source={source + '.airPlusProcurement.dbiAccountingCode'}
					label={translate('kuggar.air_plus_create_request.dbi_accounting_code')} />
			</div>
			<div>
				<TextInput
					source={source + '.airPlusProcurement.dbiOrderNumber'}
					label={translate('kuggar.air_plus_create_request.dbi_order_number')} />
			</div>
			<div>
				<DateInput
					source={source + '.airPlusProcurement.dbiDepartureDate'}
					label={translate('kuggar.air_plus_create_request.dbi_departure_date')}
					style={inputStyle} />
			</div>
			<div>
				<TextInput
					source={source + '.airPlusProcurement.dbiDepartmentCode'}
					label={translate('kuggar.air_plus_create_request.dbi_department_code')} />
			</div>
			<div>
				<TextInput
					source={source + '.airPlusProcurement.dbiInternalAccount'}
					label={translate('kuggar.air_plus_create_request.dbi_internal_account')} />
			</div>
			<div>
				<TextInput
					source={source + '.airPlusProcurement.dbiCostCentre'}
					label={translate('kuggar.air_plus_create_request.dbi_cost_centre')} />
			</div>
			<div>
				<TextInput
					source={source + '.airPlusProcurement.dbiEmployeeNumber'}
					label={translate('kuggar.air_plus_create_request.dbi_employee_number')} />
			</div>
			<div>
				<TextInput
					source={source + '.airPlusProcurement.dbiProjectNumber'}
					label={translate('kuggar.air_plus_create_request.dbi_project_number')} />
			</div>
			<div>
				<TextInput
					source={source + '.airPlusProcurement.dbiDestination'}
					label={translate('kuggar.air_plus_create_request.dbi_destination')} />
			</div>
		</span>
	);
};

const CardCreateRequestWexForm = props => {
	const translate = useTranslate();
	const { source } = props;
	const inputStyle = { width: '512px' };

	return (
		<span>
			<div>
				<NumberInput
					source={source + '.wex.amount'}
					label={translate('kuggar.wex_create_request.amount')}
					min="1"
					style={inputStyle} />
			</div>
			<div>
				<DateInput
					source={source + '.wex.activeFromDate'}
					label={translate('kuggar.wex_create_request.active_from_date')}
					style={inputStyle} />
			</div>
			<div>
				<DateInput
					source={source + '.wex.activeToDate'}
					label={translate('kuggar.wex_create_request.active_to_date')}
					style={inputStyle} />
			</div>
			<div>
				<TextInput
					source={source + '.wex.billingCurrency'}
					label={translate('kuggar.wex_create_request.billing_currency')} />
			</div>
			<div>
				<TextInput
					source={source + '.wex.localCurrency'}
					label={translate('kuggar.wex_create_request.local_currency')} />
			</div>
			<div>
				<NumberInput
					source={source + '.wex.minAmount'}
					label={translate('kuggar.wex_create_request.min_amount')}
					min="0"
					style={inputStyle} />
			</div>
			<div>
				<SelectInput source={source + '.wex.authHoldDays'}
					label={translate('kuggar.wex_create_request.auth_hold_days')}
					style={inputStyle}
					choices={[
						{ id: '0', name: '0' },
						{ id: '1', name: '1' },
						{ id: '2', name: '2' },
						{ id: '3', name: '3' },
					]}
				/>
			</div>
			<div>
				<ArrayInput label={translate('kuggar.wex_create_request.user_defined_fields')} source={source + '.wex.userDefinedFields'}>
					<SimpleFormIterator>
						<TextInput source="label" label={translate('kuggar.shared.label')} />
						<TextInput source="value" label={translate('kuggar.shared.value')} />
					</SimpleFormIterator>
				</ArrayInput>
			</div>
		</span>
	);
};

const CardCreateRequestMastercardForm = props => {
	const translate = useTranslate();
	const { source } = props;
	const inputStyle = { width: '512px' };

	return (
		<span>
			<div>
				<DateInput
					source={source + '.mastercard.validFromDate'}
					label={translate('kuggar.mastercard_create_request.valid_from_date')}
					style={inputStyle} />
			</div>
			<div>
				<DateInput
					source={source + '.mastercard.validToDate'}
					label={translate('kuggar.mastercard_create_request.valid_to_date')}
					style={inputStyle} />
			</div>
			<div>
				<NumberInput
					source={source + '.mastercard.minimumAmount'}
					label={translate('kuggar.mastercard_create_request.minimum_amount')}
					min="0"
					style={inputStyle} />
			</div>
			<div>
				<NumberInput
					source={source + '.mastercard.maximumAmount'}
					label={translate('kuggar.mastercard_create_request.maximum_amount')}
					min="0"
					style={inputStyle} />
			</div>
			<div>
				<NumberInput
					source={source + '.mastercard.maximumTransactions'}
					label={translate('kuggar.mastercard_create_request.maximum_transactions')}
					min="0"
					style={inputStyle} />
			</div>
			<div>
				<TextInput
					source={source + '.mastercard.currencyNumericCode'}
					label={translate('kuggar.mastercard_create_request.currency_numeric_code')} />
			</div>
			<div>
				<BooleanInput
					source={source + '.mastercard.isMerchantCurrency'}
					label={translate('kuggar.mastercard_create_request.is_merchant_currency')} />
			</div>
			<div>
				<BooleanInput
					source={source + '.mastercard.notifySupplier'}
					label={translate('kuggar.mastercard_create_request.notify_supplier')} />
			</div>
			<div>
				<ArrayInput label={translate('kuggar.mastercard_create_request.supplier_emails')}
					source={source + '.mastercard.supplierEmails'}>
					<SimpleFormIterator>
						<TextInput label="" />
					</SimpleFormIterator>
				</ArrayInput>
			</div>
			<div>
				<ArrayInput label={translate('kuggar.mastercard_create_request.country_codes')}
					source={source + '.mastercard.countryCodes'}>
					<SimpleFormIterator>
						<TextInput label="" />
					</SimpleFormIterator>
				</ArrayInput>
			</div>
			<div>
				<BooleanInput
					source={source + '.mastercard.strictPreAuthCheck'}
					label={translate('kuggar.mastercard_create_request.strict_pre_auth_check')} />
			</div>
			<div>
				<NumberInput
					source={source + '.mastercard.authorisationHoldDays'}
					label={translate('kuggar.mastercard_create_request.authorisation_hold_days')}
					min="0"
					style={inputStyle} />
			</div>
			<div>
				<SelectInput source={source + '.mastercard.velocityPeriod'}
					label={translate('kuggar.mastercard_create_request.velocity_period')}
					style={inputStyle}
					choices={[
						{ id: 'None', name: 'None' },
						{ id: 'Daily', name: 'Daily' },
						{ id: 'Weekly', name: 'Weekly' },
						{ id: 'Monthly', name: 'Monthly' },
						{ id: 'Quarterly', name: 'Quarterly' },
						{ id: 'Yearly', name: 'Yearly' },
						{ id: 'Continuous', name: 'Continuous' },
					]}
				/>
			</div>
			<div>
				<ArrayInput label={translate('kuggar.mastercard_create_request.curfew_days')}
					source={source + '.mastercard.curfew.daysOfWeek'}>
					<SimpleFormIterator>
						<SelectInput 
							style={inputStyle}
							label={translate('kuggar.mastercard_create_request.curfew_days')}
							choices={[
								{ id: 'Sunday', name: 'Sunday' },
								{ id: 'Monday', name: 'Monday' },
								{ id: 'Tuesday', name: 'Tuesday' },
								{ id: 'Wednesday', name: 'Wednesday' },
								{ id: 'Thursday', name: 'Thursday' },
								{ id: 'Friday', name: 'Friday' },
								{ id: 'Saturday', name: 'Saturday' },
							]}
						/>
					</SimpleFormIterator>
				</ArrayInput>
			</div>
			<div>
				<TextInput
					source={source + '.mastercard.curfew.fromTimeUtc'}
					label={translate('kuggar.mastercard_create_request.curfew_from_time')} />
			</div>
			<div>
				<TextInput
					source={source + '.mastercard.curfew.toTimeUtc'}
					label={translate('kuggar.mastercard_create_request.curfew_to_time')} />
			</div>
			<div>
				<ArrayInput label={translate('kuggar.mastercard_create_request.times_of_day')}
					source={source + '.mastercard.timesOfDay'}>
					<SimpleFormIterator>
						<SelectInput
							style={inputStyle}
							source={source + '.mastercard.timesOfDay.dayOfWeek'}
							label={translate('kuggar.mastercard_create_request.times_of_day_day')}
							choices={[
								{ id: 'Sunday', name: 'Sunday' },
								{ id: 'Monday', name: 'Monday' },
								{ id: 'Tuesday', name: 'Tuesday' },
								{ id: 'Wednesday', name: 'Wednesday' },
								{ id: 'Thursday', name: 'Thursday' },
								{ id: 'Friday', name: 'Friday' },
								{ id: 'Saturday', name: 'Saturday' },
							]}
						/>
						<TextInput
							source={source + '.mastercard.timesOfDay.fromTimeUtc'}
							label={translate('kuggar.mastercard_create_request.times_of_day_from_time')} />
						<TextInput
							source={source + '.mastercard.timesOfDay.fromTimeUtc'}
							label={translate('kuggar.mastercard_create_request.times_of_day_to_time')} />
					</SimpleFormIterator>
				</ArrayInput>
			</div>
			<div>
				<ArrayInput label={translate('kuggar.wex_create_request.custom_fields')}
					source={source + '.mastercard.customFields'}>
					<SimpleFormIterator>
						<TextInput source="label" label={translate('kuggar.shared.label')} />
						<TextInput source="value" label={translate('kuggar.shared.value')} />
					</SimpleFormIterator>
				</ArrayInput>
			</div>
		</span>
	);
};

const CardCreateRequestMintForm = props => {
	const translate = useTranslate();
	const { source } = props;
	const inputStyle = { width: '512px' };

	return (
		<span>
			<div>
				<TextInput
					source={source + '.mint.virtualCardFactoryName'}
					label={translate('kuggar.mint_create_request.virtual_card_factory_name')} />
			</div>
			<div>
				<TextInput
					source={source + '.mint.currency'}
					label={translate('kuggar.mint_create_request.currency')} />
			</div>
			<div>
				<TextInput
					source={source + '.mint.fundingAccountReference'}
					label={translate('kuggar.mint_create_request.funding_account_reference')} />
			</div>
			<div>
				<TextInput
					source={source + '.mint.externalCallReference'}
					label={translate('kuggar.mint_create_request.external_call_reference')} />
			</div>
			<div>
				<TextInput
					source={source + '.mint.cardName'}
					label={translate('kuggar.mint_create_request.card_name')} />
			</div>
			<div>
				<NumberInput
					source={source + '.mint.amount'}
					label={translate('kuggar.mint_create_request.amount')}
					min="0"
					style={inputStyle} />
			</div>
			<div>
				<TextInput
					source={source + '.mint.externalIdentifier'}
					label={translate('kuggar.mint_create_request.external_identifier')} />
			</div>
			<div>
				<TextInput
					source={source + '.mint.cardUser'}
					label={translate('kuggar.mint_create_request.card_user')} />
			</div>
			<div>
				<TextInput
					source={source + '.mint.authenticationData'}
					label={translate('kuggar.mint_create_request.authentication_data')} />
			</div>
			<div>
				<DateInput
					source={source + '.mint.clearanceDate'}
					label={translate('kuggar.mint_create_request.clearance_date')}
					style={inputStyle} />
			</div>
			<div>
				<ArrayInput label={translate('kuggar.mint_create_request.card_info')} source={source + '.mint.cardInfo'}>
					<SimpleFormIterator>
						<TextInput source="label" label={translate('kuggar.shared.label')} />
						<TextInput source="value" label={translate('kuggar.shared.value')} />
					</SimpleFormIterator>
				</ArrayInput>
			</div>
		</span>
	);
};

const CardCreateRequestKuggarForm = props => {
	const translate = useTranslate();
	const { source } = props;
	const inputStyle = { width: '512px' };

	return (
		<span>
			<div>
				<NumberInput
					source={source + '.kuggar.limit'}
					label={translate('kuggar.kuggar_create_request.limit')} 
					min="0"
					style={inputStyle} />
			</div>
			<div>
				<DateInput
					source={source + '.kuggar.activeFromDate'}
					label={translate('kuggar.kuggar_create_request.active_from_date')}
					style={inputStyle} />
			</div>
			<div>
				<DateInput
					source={source + '.kuggar.activeToDate'}
					label={translate('kuggar.kuggar_create_request.active_to_date')}
					style={inputStyle} />
			</div>
			<div>
				<TextInput
					source={source + '.kuggar.currency'}
					label={translate('kuggar.kuggar_create_request.currency')} />
			</div>
			<div>
				<ArrayInput label={translate('kuggar.kuggar_create_request.metadata')} source={source + '.kuggar.metadata'}>
					<SimpleFormIterator>
						<TextInput source="label" label={translate('kuggar.shared.label')} />
						<TextInput source="value" label={translate('kuggar.shared.value')} />
					</SimpleFormIterator>
				</ArrayInput>
			</div>
		</span>
	);
};

const CardCreateRequestForm = props => {
	const record = useRecordContext(props);
	const { provider, source } = props;

    if ((record && record.provider === 'Kuggar') || provider === 'Kuggar') {
		return (
			<CardCreateRequestKuggarForm source={source} />
		);
	}
	else if ((record && record.provider === 'eNett') || provider === 'eNett') {
		return (
			<span>
				<CardCreateRequestEnettForm source={source} />
			</span>
		);
	}
	else if ((record && record.provider === 'AirPlusFlight') || provider === 'AirPlusFlight') {
		return (
			<span>
				<CardCreateRequestAirPlusFlightForm source={source} />
			</span>
		);
	}
	else if ((record && record.provider === 'AirPlusHotel') || provider === 'AirPlusHotel') {
		return (
			<span>
				<CardCreateRequestAirPlusHotelForm source={source} />
			</span>
		);
	}
	else if ((record && record.provider === 'AirPlusProcurement') || provider === 'AirPlusProcurement') {
		return (
			<span>
				<CardCreateRequestAirPlusProcurementForm source={source} />
			</span>
		);
	}
	else if ((record && record.provider === 'WEX') || provider === 'WEX') {
		return (
			<CardCreateRequestWexForm source={source} />
		);
	}
	else if ((record && record.provider === 'Mastercard') || provider === 'Mastercard') {
		return (
			<CardCreateRequestMastercardForm source={source} />
		);
	}
	else if ((record && record.provider === 'Mint') || provider === 'Mint') {
		return (
			<CardCreateRequestMintForm source={source} />
		);
	}
	else if((record && record.provider === "Kuggar") || provider === "Kuggar") {
		return (
			<CardCreateRequestKuggarForm source={source} />
		);
	}
	else {
		return (
			null
		);
	}
};

export default CardCreateRequestForm;