import * as React from "react";
import {
    List, Datagrid, TextField, EmailField, CreateButton,
    Create, SelectInput, TextInput, ArrayInput, PasswordInput, SimpleFormIterator,
    required, email, useGetList, useNotify, useRedirect, useTranslate, useRecordContext,
    Loading, Error
} from 'react-admin';
import {WizardForm, WizardFormStep} from "@react-admin/ra-form-layout";
import {Box, Typography} from '@mui/material';
import {GetMinRoleLevel} from '../shared';
import {useState} from "react";
import CurrencySelect from '../components/CurrencySelect';
import ImageField from "../components/ImageField";
import CardCreateRequestForm from "../components/CardCreateRequestForm";
import IssuerAccountProviderCreate from "../components/IssuerAccountProviderForm";
import IssuerCredentialsProviderData from "../components/IssuerCredentialProviderForm";

export const OnboardingList = () => {
    const translate = useTranslate();

    const Empty = () => (
        <Box sx={{width: '100%', marginTop: '20px', textAlign: 'center'}}>
            <Typography variant="h6" color="inherit">{translate('kuggar.onboarding.list_title')}</Typography>
            <Typography variant="body2">{translate('kuggar.onboarding.list_subtitle')}</Typography>
            <br/>
            <CreateButton label={translate('kuggar.onboarding.create_title')}/>
        </Box>
    );

    return (
        <List empty={<Empty/>}>
            <Datagrid rowClick="show" bulkActionButtons={false}>
                <TextField source="id" label={translate('kuggar.shared.id')}/>
                <TextField source="name" label={translate('kuggar.shared.name')}/>
                <TextField source="referenceCode" label={translate('kuggar.shared.reference_code')}/>
                <EmailField source="emailAddress" label={translate('kuggar.shared.email_address')}/>
            </Datagrid>
        </List>
    );
};

export const OnboardingCreate = () => {
    const notify = useNotify();
    const redirect = useRedirect();
    const translate = useTranslate();
    const [defaultValues, setDefaultValues] = useState(null);
    const [caches, setCaches] = useState(null);
    const [provider, setProvider] = useState();
    
    const { data: issuerAccountData, isLoading: issuerAccountLoading, error: issuerAccountError } = useGetList(
        'issuer_account',
        {
            filter: {
            },
            pagination: {
                page: 1,
                perPage: 1000
            },
            sort: {
            }
        }
    );

    const {data: cacheData, isLoading: cacheLoading, error: cacheError} = useGetList(
        'cache',
        {
            filter: {},
            pagination: {
                page: 1,
                perPage: 1000
            },
            sort: {
                field: 'level',
                order: 'ASC'
            }
        }
    );

    if (issuerAccountLoading) return <Loading />;
    if (issuerAccountError) return <Error />;
    if (!issuerAccountData) return null;

    if (cacheLoading) return <Loading />;
    if (cacheError) return <Error />;
    if (!cacheData) return null;

    const result = cacheData.filter(d => Array.isArray(d.virtualCards) && d.virtualCards.length !== 0);
    if(caches === null) setCaches(result);

    const onSuccess = () => {
        notify(`${translate('kuggar.organisation.on_success_notify')}`);
        redirect(`/organisation`);
    };

    const inputStyle = {width: '100%'};

    const GetCustomerAccessProfiles = () => {
        const {data, isLoading, error} = useGetList(
            'access_profile/list',
            {
                filter: {},
                pagination: {
                    page: 1,
                    perPage: 1000
                },
                sort: {
                    field: 'description',
                    order: 'ASC'
                }
            }
        );
        if (isLoading || error || !data) return [];

        return data;
    }

    const CustomerAccessProfileSelectInput = () => {
        const translate = useTranslate();

        const optionRenderer = choice => <div>{choice.description}</div>;

        return (
            <SelectInput source="customerAccessProfileId" label={translate('kuggar.onboarding.customer_access_profile')}
                         choices={GetCustomerAccessProfiles()} optionText={optionRenderer} fullWidth={true}
                         validate={[required()]}/>
        );
    };

    const GetRoles = () => {
        const {data, isLoading, error} = useGetList(
            'role',
            {
                filter: {},
                pagination: {
                    page: 1,
                    perPage: 1000
                },
                sort: {
                    field: 'level',
                    order: 'ASC'
                }
            }
        );
        if (isLoading || error || !data) return [];

        const highestRoleLevel = GetMinRoleLevel();

        return data.map(role => ({...role, disabled: role.level < highestRoleLevel}));
    }
    
    const RoleSelectInput = () => {
        const translate = useTranslate();

        const optionRenderer = choice => <div>{choice.name}<br/><span
            style={{fontSize: '0.875rem', color: 'rgba(0,0,0,0.6)'}}>Level {choice.level}. {choice.description}</span>
        </div>;

        return (
            <SelectInput source="roleId" label={translate('kuggar.onboarding.role')} choices={GetRoles()}
                         optionText={optionRenderer} fullWidth={true} validate={[required()]}/>
        );
    };
    
    const GetEncryptionKeys = () => {
        const {data, isLoading, error} = useGetList(
            'encryption_key/list',
            {
                filter: {},
                pagination: {
                    page: 1,
                    perPage: 1000
                },
                sort: {
                    field: 'level',
                    order: 'ASC'
                }
            }
        );
        if (isLoading || error || !data) return [];

        const highestRoleLevel = GetMinRoleLevel();

        return data.map(role => ({...role, disabled: role.level < highestRoleLevel}));
    }
    
    const EncryptionKeySelectInput = () => {
        const translate = useTranslate();

        const optionRenderer = choice => <div>{choice.description}</div>;

        return (
            <SelectInput source="encryptionKeyId" label={translate('kuggar.onboarding.encryption_key')}
                         choices={GetEncryptionKeys()} optionText={optionRenderer} fullWidth={true}
                         validate={[required()]}/>
        );
    };

    const GetIssuerEnvironments = () => {
        const {data, isLoading, error} = useGetList(
            'issuer_environment/list',
            {
                filter: {},
                pagination: {
                    page: 1,
                    perPage: 1000
                },
                sort: {
                    field: 'level',
                    order: 'ASC'
                }
            }
        );
        if (isLoading || error || !data) return [];

        const highestRoleLevel = GetMinRoleLevel();

        return data.map(role => ({...role, disabled: role.level < highestRoleLevel}));
    }
    
    const IssuerEnvironmentSelectInput = () => {
        const translate = useTranslate();

        const optionRenderer = choice => <div>{choice.description}</div>;

        return (
            <SelectInput source="issuerEnvironmentId" label={translate('kuggar.onboarding.issuer_environment')}
                         choices={GetIssuerEnvironments()} optionText={optionRenderer} fullWidth={true}
                         validate={[required()]}/>
        );
    };
    
    const GetCaches = () => {

    }

    const onCacheChange = (value) => {
        const cacheId = value.target.value;
        const selectedCache = caches.find(c => c.id === cacheId);
        const userDefinedFields = selectedCache.virtualCards[0].userDefinedFields;
        const accountDefinedData = getAccountDefinedData(selectedCache.issuerAccountId);

        const userDefinedData = accountDefinedData.map((data, index) => {
            let type = 0;
            if (data.type === 'Text') type = 1;
            else if (data.type === 'Date') type = 2;
            else if (data.type === 'Number') type = 3;

            const udf = userDefinedFields.find(udd => udd.label === data.label)
            const value = udf?.value ?? userDefinedFields[index].value;

            return {label: data.label, type, value}
        });

        setDefaultValues({
            issuerAccountId: selectedCache.issuerAccountId,
            userDefinedData: userDefinedData
        });
    };

    const getAccountDefinedData = (issuerAccountId) => {
        const issuerAccount = issuerAccountData.find(element => element.id === issuerAccountId);
        return issuerAccount.accountDefinedData;
    };

    const OptionRenderer = () => {
        const record = useRecordContext();
        return <span><ImageField source="id" path="cards" height="30"
                                 label={translate('kuggar.shared.provider')}/>&nbsp;&nbsp;{record.name}</span>;
    };

    return (
        <Create title={translate('kuggar.onboarding.create_title')} mutationOptions={{onSuccess}}>
            <WizardForm>
                <WizardFormStep label="Organisation">
                    <SelectInput source="provider"
                                 onChange={(e) => setProvider(e.target.value)}
                                 label={translate('kuggar.shared.provider')}
                                 validate={[required()]}
                                 style={inputStyle}
                                 choices={[
                                     {id: 'AirPlusFlight', name: 'AirPlus - Flight'},
                                     {id: 'AirPlusHotel', name: 'AirPlus - Hotel'},
                                     {id: 'AirPlusProcurement', name: 'AirPlus - Procurement'},
                                     {id: 'eNett', name: 'eNett'},
                                     {id: 'Kuggar', name: 'Kuggar'},
                                     {id: 'Mastercard', name: 'Mastercard'},
                                     {id: 'Mint', name: 'Nium'},
                                     {id: 'WEX', name: 'WEX'},
                                 ]}
                                 optionText={<OptionRenderer/>}
                    />
                    <TextInput source="organisationName" label={translate('kuggar.shared.name')} fullWidth={true}
                               validate={[required()]}/>
                    <TextInput source="organisationRef" label={translate('kuggar.shared.reference_code')}
                               fullWidth={true} validate={[required()]}
                               helperText={translate('kuggar.organisation.reference_code_validation')}
                               format={v => v == null ? null : v.toUpperCase()}/>
                    <TextInput source="organisationEmail" label={translate('kuggar.onboarding.email_address')}
                               fullWidth={true}
                               validate={[required(), email('kuggar.shared.email_address_validation')]}/>
                </WizardFormStep>
                <WizardFormStep label="Users">
                    <ArrayInput source="userEmails" label={translate('kuggar.onboarding.user_values')}>
                        <SimpleFormIterator fullWidth={true} inline>
                            <TextInput source="" label={translate('kuggar.shared.email_address')} fullWidth={true}
                                       validate={[required(), email('kuggar.shared.email_address_validation')]}/>
                        </SimpleFormIterator>
                    </ArrayInput>
                    <RoleSelectInput/>
                </WizardFormStep>
                <WizardFormStep label="Security">
                    <CustomerAccessProfileSelectInput/>
                    <TextInput source="accessProfileName" label={translate('kuggar.onboarding.access_profile_name')}
                               fullWidth={true} validate={[required()]}/>
                    <TextInput source="authenticationDomain"
                               label={translate('kuggar.onboarding.authentication_domain')} fullWidth={true}
                               validate={[required()]}/>
                    <EncryptionKeySelectInput/>
                    <IssuerEnvironmentSelectInput/>
                    <IssuerCredentialsProviderData provider={provider}/>
                </WizardFormStep>
                <WizardFormStep label="Issuer Accounts and Caches">
                    <ArrayInput source="issuerAccountContainer"
                                label={translate('kuggar.onboarding.issuer_account_values')}>
                        <SimpleFormIterator fullWidth={true} inline>
                            <IssuerAccountProviderCreate provider={provider} source={'issuerAccountData'}/>
                            <CardCreateRequestForm provider={provider} source={'createRequestData'}/>
                        </SimpleFormIterator>
                    </ArrayInput>
                </WizardFormStep>
                <WizardFormStep label="Enhanced Data Fields">
                    <ArrayInput source="userDefinedData"
                                label={translate('kuggar.onboarding.user_defined_data_values')}>
                        <SimpleFormIterator fullWidth={true} inline>
                            <TextInput source="label" label={translate('kuggar.onboarding.user_defined_data_key')}
                                       fullWidth={true} validate={[required()]}/>
                            <SelectInput source="type" label={translate('kuggar.onboarding.user_defined_data_type')}
                                         fullWidth={true} validate={[required()]} choices={[
                                {id: '0', name: 'None'},
                                {id: '1', name: 'Text'},
                                {id: '2', name: 'Date'},
                                {id: '3', name: 'Number'},
                            ]}/>
                            <TextInput source="value" label={translate('kuggar.onboarding.user_defined_data_value')}
                                       fullWidth={true} validate={[required()]}/>
                        </SimpleFormIterator>
                    </ArrayInput>
                </WizardFormStep>
                <WizardFormStep label="Issuer FTP">
                    <TextInput source="issuerFtpData.host" label={translate('kuggar.onboarding.issuer_ftp_host')}
                               fullWidth={true} validate={[required()]}/>
                    <TextInput source="issuerFtpData.port" label={translate('kuggar.onboarding.issuer_ftp_port')}
                               fullWidth={true} validate={[required()]}/>
                    <TextInput source="issuerFtpData.username"
                               label={translate('kuggar.onboarding.issuer_ftp_username')} fullWidth={true}
                               validate={[required()]}/>
                    <PasswordInput source="issuerFtpData.password"
                                   label={translate('kuggar.onboarding.issuer_ftp_password')} fullWidth={true}
                                   validate={[required()]}/>
                    <ArrayInput source="issuerFtpData.directories"
                                label={translate('kuggar.onboarding.issuer_ftp_directories')} fullWidth={true}>
                        <SimpleFormIterator fullWidth={true}>
                            <TextInput source="" label={false} fullWidth={true} validate={[required()]}/>
                        </SimpleFormIterator>
                    </ArrayInput>
                </WizardFormStep>
            </WizardForm>
        </Create>
    );
};